import Vue from "vue";
import Vuex from "vuex";
import router from "../router";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    base_url: "https://creativeitinstitute.com/",
    token: localStorage.getItem("token") || null,
    user_name: localStorage.getItem("user_name") || null,
    role_name: localStorage.getItem("role_name") || null,

    getOptionCategory: {},
    getSoftware: {},
    getOption: {},
    getDepartment: [],
    getSuccessStoryCategory: [],
    getReviewCategory: [],
    getSuccessStory: [],
    getFacilities: [],
    getMarketplace: [],
    getCareerPlacementPartner: [],
    getCourse: [],
    getNewsCategory:[]
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
    getNewsCategory(state) {
      return state.getNewsCategory;
    },
    getOptionCategory(state) {
      return state.getOptionCategory;
    },
    getSoftware(state) {
      return state.getSoftware;
    },
    getOption(state) {
      return state.getOption;
    },
    getDepartment(state) {
      return state.getDepartment;
    },
    getSuccessStoryCategory(state) {
      return state.getSuccessStoryCategory;
    },
    getReviewCategory(state) {
      return state.getReviewCategory;
    },
    getSuccessStory(state) {
      return state.getSuccessStory;
    },
    getFacilities(state) {
      return state.getFacilities;
    },
    getMarketplace(state) {
      return state.getMarketplace;
    },
    getCareerPlacementPartner(state) {
      return state.getCareerPlacementPartner;
    },
    getCourse(state) {
      return state.getCourse;
    },
  },
  mutations: {
    setToken(state, data) {
      state.token = data.token;
      state.user_name = data.name;
      state.role_name = data.role_name;
    },
    removeToken(state) {
      state.token = null;
      state.user_name = null;
      state.role_name = null;
    },

    getNewsCategory(state, data) {
      return (state.getNewsCategory = data);
    },
    getOptionCategory(state, data) {
      return (state.getOptionCategory = data);
    },
    getSoftware(state, data) {
      return (state.getSoftware = data);
    },
    getOption(state, data) {
      return (state.getOption = data);
    },
    getDepartment(state, data) {
      return (state.getDepartment = data);
    },
    getSuccessStoryCategory(state, data) {
      return (state.getSuccessStoryCategory = data);
    },
    getReviewCategory(state, data) {
      return (state.getReviewCategory = data);
    },
    getSuccessStory(state, data) {
      return (state.getSuccessStory = data);
    },
    getFacilities(state, data) {
      return (state.getFacilities = data);
    },
    getMarketplace(state, data) {
      return (state.getMarketplace = data);
    },
    getCareerPlacementPartner(state, data) {
      return (state.getCareerPlacementPartner = data);
    },
    getCourse(state, data) {
      return (state.getCourse = data);
    },
  },
  actions: {
    removeToken(context) {
      context.commit("removeToken");
    },
    setToken(context, data) {
      context.commit("setToken", data);
    },

    CheckAuth(content) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + localStorage.getItem("token");
      axios
        .post("/admin/check")
        .then((res) => {
          if (!res.data.status) {
            localStorage.removeItem("token");
            content.commit("removeToken");
            router.push("/login");
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            localStorage.removeItem("token");
            content.commit("removeToken");
            router.push("/login");
          }
        });
    },
    getNewsCategory(content) {
      axios
        .get("/get/news/category")
        .then((res) => {
          content.commit("getNewsCategory", res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    getOptionCategory(content) {
      axios
        .post("/app/option/category/get")
        .then((res) => {
          content.commit("getOptionCategory", res.data.categories);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getSoftware(content) {
      axios
        .post("/app/course/software")
        .then((res) => {
          content.commit("getSoftware", res.data.softwares);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getOption(content) {
      axios
        .post("/app/get/all/options")
        .then((res) => {
          content.commit("getOption", res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getDepartment(content) {
      axios
        .post("/app/course/department/get")
        .then((res) => {
          content.commit("getDepartment", res.data.departments);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getSuccessStoryCategory(content) {
      axios
        .post("/app/success/story/category/get")
        .then((res) => {
          content.commit("getSuccessStoryCategory", res.data.categories);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getReviewCategory(content) {
      axios
        .post("/app/review/category/get")
        .then((res) => {
          content.commit("getReviewCategory", res.data.categories);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getSuccessStory(content) {
      axios
        .post("/app/all/success/story/get")
        .then((res) => {
          content.commit("getSuccessStory", res.data.success_stories);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getFacilities(content) {
      axios
        .post("/app/course/facilities/get")
        .then((res) => {
          content.commit("getFacilities", res.data.facilities);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getMarketplace(content) {
      axios
        .post("/app/course/marketplace/get")
        .then((res) => {
          content.commit("getMarketplace", res.data.marketplaces);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCareerPlacementPartner(content) {
      axios
        .post("/app/career/placement/partner/all/get")
        .then((res) => {
          content.commit("getCareerPlacementPartner", res.data.partners);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getCourse(content) {
      axios
        .post("/app/course/all/get")
        .then((res) => {
          content.commit("getCourse", res.data.courses);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
});
